import axios from 'axios';

export const doPayment = (key, amount, source, dk_fee) => {

    const body = {
      "idempotency_key": key,
      "amount_money": {
        "amount": amount,
        "currency": "USD"
      },
      "source_id": source,
      "app_fee_money": {
        "amount": dk_fee,
        "currency": "USD"
      },
      "location_id": "LSQ55HR7G2VW5" 
    };
    const headers = {
      'Content-Type': 'application/json'
    };

    console.log(body)
    return axios
      .post('https://api.debtkarma.io/api/doPayment', body, { headers })
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return (console.log(error));
      });
  };