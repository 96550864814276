import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col, Modal } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Profile extends React.Component {
  constructor() {
    super()

    this.state = {
      isPaymentPending: true,
      access_token: null,
      url: "",
      description: "Add your description here. Tell us about yourself. This is a great place to add your story for the world to see.",
      alumni: "",
      loadingData: true,
      first_name: "",
      last_name: "",
      exampleModal: false
   
    }
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  
  componentDidMount() {
    const { match: { params } } = this.props;
    this.loadingData();

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  getData() {
    this.setState({ loading: true });
    let params = `?firebase_id=${this.props.match.params.id}`

    fetch(`https://api.debtkarma.io/api/db/user${params}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
            first_name: res[0].first_name,
            last_name: res[0].last_name,
            url: res[0].image,
            description: res[0].user_description,
            alumni: res[0].alumni,
            loadingData: false
        })
        this.getCampaignData();
      })
    
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  getCampaignData() {
    this.setState({
      loadingCampaigns: true
    })

    let params = `?firebase_id=${this.props.match.params.id}`

    fetch(`https://api.debtkarma.io/api/db/campaigns${params}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
           data: res,
           loadingCampaigns: false
        })

        console.log(this.state.data)
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }



  loadingData() {
    this.setState({loadingData: true})
    this.getData()
        }


        renderCampaigns() {
          if (this.state.loadingCampaigns === false && this.state.data.length) {
          return(

            this.state.data.map((item) =>

            <div className="card mb-3 card-lift--hover" onClick={ () => this.handleClick(item) } style={{width: '100%', cursor: 'pointer'}}>
            <div className="row no-gutters">
              <div className="col-md-4">
                <img
                  className="bd-placeholder-img"
                  width="100%"
                  height="100%"
                  src={item.photo}
                />
              </div>
              <div className="col-md-8" style={{backgroundColor: "whitesmoke"}}>
                <div className="card-body">
                  <div className="card-text">
                    <p className="text-muted font-italic">{item.campaign_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
          )
          }
          return(
            <p>{this.state.first_name} is in the process of creating his donation campaigns. Once a campaign is created, you will be able to donate directly to help Jacob get a little closer to paying off his student loans. </p>
          )
        }

        handleClick(item) {
          this.props.history.push({
              pathname: `/campaign/${item.guid}`,
              state: { user: item}
            })
        }

  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={this.state.url}
                          />
                        </a>
                      </div>
                    </Col>

                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={() => this.toggleModal("exampleModal")}
                          size="sm"
                        >
                          Donate now
                        </Button>
                      </div>
                    </Col>

                    <>
        {/* Button trigger modal */}
        {/* Modal */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Select campaign:
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.renderCampaigns()}
          </div>
        </Modal>
      </>


                    <Col className="order-lg-1" lg="4">
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      {this.state.first_name} {this.state.last_name}
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.alumni}
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                      <h5>About me:</h5>
                        <p>
                          {this.state.description}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <h5 style={{marginBottom: '50px'}}>Campaigns:</h5>
                        <p>
                          {this.renderCampaigns()}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Profile;
