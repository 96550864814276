import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 style={{marginTop: '40px'}} className="display-3 text-white">
                        Solving Student Loan Debt
                      </h1>
                      <p className="lead text-white">
                      Tackling the student loan crisis one donation at a time. Receive donations or contribute directly to student loan accounts.
                      </p>
                      <div className="btn-wrapper">
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-spaceship" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Tutorial
                          </h6>
                          <p className="description mt-3">
                            Learn how to receive or give donations directly to student loans with Debt Karma
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              receive
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              donate
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              share
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-zoom-split-in" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Find Users
                          </h6>
                          <p className="description mt-3">
                          Search users by name or alumni and make donations directly to their student loans
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              search
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              students
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              alumni
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="/discover"
                          >
                            Find Users
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-circle-08" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Create Account
                          </h6>
                          <p className="description mt-3">
                            uild your own profile and start receiving donations today
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              campaigns
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              donations
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              freedom
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
               
                    <h3>Mobile App</h3>
                    <p>
                    Download the mobile app today and get started creating campaigns and receiving donations directly towards your student loans
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="info"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Easy to navigate
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="info"
                            >
                              <i className="ni ni-notification-70" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Quickly create campaigns</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="info"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Share with friends and family
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
