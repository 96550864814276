import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Progress
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Campaign extends React.Component {
    constructor() {
        super()
    
        this.state = {
          isPaymentPending: true,
          access_token: null,
          url: "",
          description: "Add your description here. Tell us about yourself. This is a great place to add your story for the world to see.",
          alumni: "",
          loadingData: true,
          first_name: "",
          last_name: "",
     
       
        }
      }
  

  componentDidMount() {
      console.log(this.props.match.params.id)
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.loadingData();
  }

  loadingData() {
    this.setState({loadingData: true})
    this.getCampaignData()
        }


  handleClick() {
    this.props.history.push({
        pathname: `/donate/`,
        state: { campaign: this.state.data}
      })
  }

  getCampaignData() {

    let params = `?guid=${this.props.match.params.id}`

    fetch(`https://api.debtkarma.io/api/db/campaign${params}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
            data: res[0],
            loadingData: false,
        })

        console.log(res)
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
    
  
  }

  renderCampaign() {
      if (this.state.loadingData) {

      } else {

      return(
        <Card className="shadow border-0 mt--400" id="cam-card">
        <CardBody className="py-5 card text-center">
          <img
             className="cam-card-image"
             src={this.state.data.photo}
             style={{maxWidth: "400px", borderRadius: ".25rem", transform: 'translate(-50%,-40%)', marginLeft: "42%", alignSelf: 'center'}} 
           />
          <h4 className="text-default">
            {this.state.data.title}
          </h4>
          <Col lg="6" style={{alignSelf: 'center'}}>
          <p className="description mt-3" style={{marginBottom: '45px'}}>
          {this.state.data.campaign_description}
          </p>
          <Progress value={(this.state.data.amount_donated / this.state.data.target) * 100} />
          </Col>
          <h6 className="description mt-3">
          ${this.state.data.amount_donated} raised of ${this.state.data.target}
          </h6>
          <Button
            style={{width: "30%", alignSelf: "center"}}
            className="mt-2"
            id="donate-button"
            color="default"
       
            onClick={() => this.handleClick()}
          >
            Donate
          </Button>
        </CardBody>
      </Card>
      )
      }
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6" style={{marginBottom: '200px'}}>
              
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="12">
                        {this.renderCampaign()}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Campaign;
