import React from "react";


// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";


class Mission extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default"></div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1
                        style={{ marginTop: "40px" }}
                        className="display-3 text-white"
                      >
                        Our Mission
                      </h1>
                      <p className="lead text-white">
                        To relieve our communities of the struggles of student
                        debt by providing a simple, quick and secure process to
                        donate directly to a student loan account.
                      </p>
                      <div className="btn-wrapper"></div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="shadow border-0">
                        <CardBody className="card">
                          <h2 className="text-primary text-uppercase">
                            Simple
                          </h2>
                          <p className="pt-2 lead text-default">
                            We believe in making this experience as simple as
                            possible. Our user-friendly interface allows you to
                            easily navigate through the site. Please visit our
                            tutorials page to learn more.{" "}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="shadow border-0">
                        <CardBody className="card">
                          <h2 className="text-success text-uppercase">Quick</h2>
                          <p className="pt-2 lead text-default">
                            Your time is very valuable to us. Debt
                            Karma has designed this software for everyone to quickly
                            create/find student profiles and make donations to
                            their campaigns.{" "}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="shadow border-0">
                        <CardBody className="card">
                          <h2 className="text-warning text-uppercase">
                            Secure
                          </h2>
                          <p className="pt-2 lead text-default">
                            At Debt Karma, we are committed to ensuring the
                            safety and security of all users. Our talented team
                            dedicates their time to prevent fraud and provide a
                            worry free donation process.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Mission;
