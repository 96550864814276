import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Confirmation extends React.Component {
    constructor() {
        super()

        this.state = {
        }
    }


    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }

    renderConfirmation() {

        return (
            <Card className="shadow border-0">
                <CardBody className="py-5 card text-center">
                    <h3>
                        Thank you for your donation! Your confirmation number is {this.props.location.state.confirmation}
                    </h3>

                    <ion-icon 
                        style={{ fontSize: '100px',
                                 color: 'green',
                                 alignSelf: 'center',
                                 marginTop: '40px',
                                 marginBottom: '40px'}} 
                        name="checkmark-circle-outline"
                    />

                    <h4>
                        You'll receive an email confirmation for your records within the next 24 hours.
                    </h4>
                </CardBody>
            </Card>
        )
    }

    render() {
        return (
            <>
                <DemoNavbar />
                <main ref="main">
                    <div className="position-relative">
                        {/* shape Hero */}
                        <section className="section section-lg section-shaped pb-250">
                            <div className="shape shape-style-1 shape-default">
                            </div>
                            <Container className="py-lg-md d-flex">
                                <div className="col px-0">
                                    <Row>
                                        <Col lg="6" style={{ marginBottom: '200px' }}>

                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                            {/* SVG separator */}
                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="2560 0 2560 100 0 100"
                                    />
                                </svg>
                            </div>
                        </section>
                        {/* 1st Hero Variation */}
                    </div>
                    <section className="section section-lg pt-lg-0 mt--300">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <Row className="row-grid">
                                        <Col lg="12">
                                            {this.renderConfirmation()}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                </main>
                <SimpleFooter />
            </>
        );
    }
}

export default Confirmation;
