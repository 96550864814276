import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";
import Typography from "views/IndexSections/Typography.js";
import { unstable_renderSubtreeIntoContainer } from "react-dom";

class Discover extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          // firebase_id: this.user.uid,
          loading: false,
          data: [],
          error: null,
          school: "",
          access_token: null,
          modalVisible: false,
          message: "",
          email: ""
    
        };
    
        this.arrayholder = [];
      }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.makeRemoteRequest();
  }

  makeRemoteRequest = () => {

    this.setState({ loading: true });

    fetch(`https://api.debtkarma.io/api/db/users`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          data: res,
          error: res.error || null,
          loading: false,
        });
        this.arrayholder = res;
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  searchFilterFunction(text) {
    this.setState({
      value: text.target.value,
    });

    const newData = this.arrayholder.filter(item => {
      const itemData = `${item.first_name.toUpperCase()} ${item.last_name.toUpperCase()} ${item.alumni.toUpperCase()}`;
      const textData = text.target.value.toUpperCase();

      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      data: newData,
    });
  };

  handleClick(item) {
    this.props.history.push({
        pathname: `/user/${item.firebase_id}`,
        state: { user: item}
      })
  }

  renderUsers() {
    return(

        this.state.data.map((item) => 


    <Col className="mb-5 mb-lg-0 card-lift--hover" style={{cursor: 'pointer'}} lg="3" md="6" key={item.firebase_id}>
    <div className="px-5" onClick={ () => this.handleClick(item) }>
      <div
        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
        style={{ backgroundImage: `url(${item.image})`,     
          width: '100px',
          height: '100px',
          borderRadius: '50px',
          backgroundClip: 'padding-box',
          backgroundSize: 'cover',
          backgroundPosition: 'center center' }}
      />
      <div className="pt-4 text-center" style={{marginBottom: '50px'}}>
        <h5 className="title">
          <span className="d-block mb-1">{item.first_name} {item.last_name}</span>
          <small className="h6 text-muted">{item.alumni}</small>
        </h5>
      </div>
    </div>
  </Col>
    )
    )
}

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 style={{marginTop: '40px'}} className="display-3 text-white">
                        Browse Users
                      </h1>
                      <p className="lead text-white">
                      Search users by name or alumni and make donations directly to their student loans
                      </p>
                      <div className="btn-wrapper">
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg">
            <Container>
            <Input
                      placeholder="Search by name or alumni"
                      type="text"
                      onFocus={e => this.setState({ searchFocused: true })}
                      onBlur={e => this.setState({ searchFocused: false })}
                      onChange={text => this.searchFilterFunction(text)}
                      autoCorrect={false}
                      value={this.state.value}
                    />

              <Row className="row-grid align-items-center">
        
                <Col className="order-md-1" md="12">
                <section className="section section-lg">
            <Container>
              <Row>
                {this.renderUsers()}
              </Row>
            </Container>
          </section>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Discover;
