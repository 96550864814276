import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  UncontrolledAlert,
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Progress
} from "reactstrap";

import SquarePaymentForm, {
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton
} from "react-square-payment-form";
import "react-square-payment-form/lib/default.css";
import { v4 as uuidv4 } from 'uuid';
import { doPayment } from '../../api/api.js';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Donate extends React.Component {
  constructor() {
    super();

    this.state = {
      isPaymentPending: true,
      isFocused: true,
      loading: false,
      allowed: false,
      complete: true,
      status: null,
      token: null,
      amexAvailable: false,
      discoverAvailable: false,
      masterCardAvailable: false,
      visaAvailable: false,
      amount: null,
      cardBtn: "Card",
      showPaymentButton: "none",
      showCardButton: "flex",
      showApplePayButton: "flex",
      showCancelButton: "none",
      paymentButtonColor: "default",
      paymentProcessing: false,
      anonymous: false,
      email: '',
      confirmation: "1145434343",
      guid: null,
      timestamp: null,
      loading: false,
      firebase_id: null,
      campaign_title: 'generic profile donation',
      campaign_guid: 'generic profile donation',
      donor_first_name: '',
      donor_last_name: '',
      is_anonymous: 0,
      donor_comment: '',
      donor_zip: '',
      amount_after_fees: null,
      squareCardInfo: null,
      alert: 'none',
      alertChecked: false,
      donationAmount: null,
      errorMessages: [],
      amountValidation: "Donation amount is required",
      formValidation: "Please fill out all required fields",
      pointerEvents: 'none',
      opacity: '0.4',
      validForm: false,
      amountValid: false,
      confirmation: '123',
      user_email: '',
      notGuid: ''
    };

  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    console.log(this.props.location.state.campaign)
    this.generatePaymentGuid()
    this.getUserEmail()
  }

  getUserEmail() {
    let params = `?firebase_id=${this.props.location.state.campaign.firebase_id}`

    fetch(`https://api.debtkarma.io/api/db/user${params}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
            user_email: res[0].email,
        })
        console.log(this.state.user_email)
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  handleChange(event) {
    this.setState({ donationAmount: event.target.value });
  }

  handleChangeFirstName(event) {
    this.setState({ donor_first_name: event.target.value });
  }

  handleChangeLastName(event) {
    this.setState({ donor_last_name: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeZip(event) {
    this.setState({ donor_zip: event.target.value });
  }

  handleChangeComment(event) {
    this.setState({ donor_comment: event.target.value });
  }

  enablePayButton() {
    this.setState({
      pointerEvents: 'all',
      opacity: '1'
    })
  }

  displayAlert() {
    if (this.state.alertChecked)
      return (
        <UncontrolledAlert color="default" style={{ textAlign: 'left' }}>
          The person receiving this donation will not know who it is from. However, Debt Karma
          will still collect your name, email address and zip code for payment processing purposes.
        </UncontrolledAlert>
      )
  }

  renderProcessingFee() {
    if (this.state.donationAmount)
      return (
        <p>Processing fee: ${((this.state.donationAmount * .079) + .30).toFixed(2)}</p>
      )
  }

  renderErrors() {
    return (
      <div className="sq-error-message">
        {this.state.errorMessages.map(errorMessage => (
          <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
        ))}
      </div>
    )
  }

  renderAmountValidation() {
    if (this.state.donationAmount <= 0 || this.state.donationAmount === null) {
      return (
        <div className="validation">
          <li>{this.state.amountValidation}</li>
        </div>
      )
    }
  }

  renderFormValidation() {
    if (this.state.donor_first_name === '' ||
        this.state.donor_last_name === '' ||
        this.state.email === '' ||
        this.state.donor_zip === '') {
      return (
        <div className="validation">
          <li>{this.state.formValidation}</li>
        </div>
      )
    }
  }

  disablePayButton() {
    if (this.state.donationAmount <= 0 || this.state.donationAmount === null) {
    } else {
      this.setState({
        pointerEvents: 'all',
        opacity: 1
      })
    }
  }

  renderPaymentButton() {
    if (this.state.donationAmount <= 0 ||
        this.state.donationAmount === null ||
        this.state.donor_first_name === '' ||
        this.state.donor_last_name === '' ||
        this.state.email === '' ||
        this.state.donor_zip === '') {

    } else {
      return (
        <CreditCardSubmitButton>
          Pay ${this.state.donationAmount}.00
        </CreditCardSubmitButton>
      )
    }
  }

  renderPaymentPage() {
    return (
      <div>

        <SquarePaymentForm
          applicationId="sq0idp-1foqlcktO6m7iwV25Nnz8Q"
          locationId="LSQ55HR7G2VW5"
          cardNonceResponseReceived={this.cardNonceResponseReceived}
          // createVerificationDetails={this.createVerificationDetails}
        >
          <fieldset className="sq-fieldset">
            <CreditCardNumberInput />

            <div className="sq-form-third">
              <CreditCardExpirationDateInput />
            </div>

            <div className="sq-form-third">
              <CreditCardPostalCodeInput />
            </div>

            <div className="sq-form-third">
              <CreditCardCVVInput />
            </div>
          </fieldset>

          {this.renderPaymentButton()}

        </SquarePaymentForm>

        {this.renderErrors()}
        {this.renderAmountValidation()}
        {this.renderFormValidation()}
      </div>
    )
  }

  generatePaymentGuid() {
    let uuid = uuidv4();
    let guid = 'DON-' + uuid
    let timestamp = Date.parse(new Date());

    let confirmation = uuidv4();
    let notificationGuid = uuidv4();
    let notGuid = 'NOT-' + notificationGuid

    this.setState({
      guid, timestamp, confirmation, notGuid
    })
  }

  cardNonceResponseReceived = (
    errors,
    nonce,
    cardData,
    buyerVerificationToken
  ) => {
    if (errors) {
      this.setState({ errorMessages: errors.map(error => error.message) });
      return;
    }

    this.setState({ errorMessages: [] });

    this.submitPayment(nonce, cardData)
  }

    submitPayment = (nonce, cardData) => {
  
      let paymentAmount = this.state.donationAmount + '00'

      let pFee = (parseInt(this.state.donationAmount) * .079) + .3;
      let processFee = (Math.round(pFee * 100) / 100).toFixed(2);
      let processFeeCents = processFee * 100

      let totAfterFees = (this.state.donationAmount - processFee)

      let dk_fee = (Math.round((paymentAmount - processFeeCents) * .05))

      this.setState({
        paymentProcessing: true,
        amount_after_fees: totAfterFees
      })
      doPayment(this.state.guid, parseInt(paymentAmount), nonce, parseInt(dk_fee))
      .then(() => {
        console.log('Payment made successfully!');

  
          let params = `?email=${this.state.email}&confirmation=${this.state.confirmation}&last4=${cardData.last_4}&amount=${this.state.donationAmount}`
          fetch(`https://api.debtkarma.io/api/send-email${params}`, {
            method: "POST",
          })

          let email_params = `?user_email=${this.state.user_email}`
          fetch(`https://api.debtkarma.io/api/send-email-notification${email_params}`, {
            method: "POST",
          })

          let more_params = `?guid=${this.props.location.state.campaign.guid}&amount_donated=${this.state.donationAmount}`
          console.log(more_params)
          fetch(`https://api.debtkarma.io/api/db/campaign${more_params}`, {
            method: "PUT",
          })

          let notification_params = `?firebase_id=${this.props.location.state.campaign.firebase_id}&guid=${this.state.notGuid}&creation_timestamp=${this.state.timestamp}`
          fetch(`https://api.debtkarma.io/api/db/notifications/donation${notification_params}`, {
            method: "POST",
          })

          let parameters = `?guid=${this.state.guid}&receiving_user_id=${this.props.location.state.campaign.firebase_id}&creation_timestamp=${this.state.timestamp}&campaign_title=${this.props.location.state.campaign.title}&campaign_guid=${this.props.location.state.campaign.guid}&donation_amount=${this.state.donationAmount}&amount_after_fees=${this.state.amount_after_fees}&donor_first_name=${this.state.donor_first_name}&donor_last_name=${this.state.donor_last_name}&is_anonymous=${this.state.is_anonymous}&donor_comment=${this.state.donor_comment}&donor_email=${this.state.email}&donor_zip=${this.state.donor_zip}&card_last_four=${cardData.last_4}&confirmation=${this.state.confirmation}`
          console.log(parameters)
            fetch(`https://api.debtkarma.io/api/db/donations${parameters}`, {
              method: "POST",
            })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson);
              this.setState({
                loading: false
              })
           
            })
                .catch(function(error) {
                  console.log(
                    "There has been a problem with your fetch operation: " + error
                  );
                  throw error;
                });

                this.props.history.push({
                  pathname: `/confirmation/`,
                  state: { amount: this.state.donationAmount, confirmation: this.state.confirmation}
                })
        
      })
      .finally(() => {
        this.setState({ isPaymentPending: false, paymentProcessing: false });
      });
      }

  // createVerificationDetails() {
  //   return {
  //     amount: this.state.donationAmount,
  //     currencyCode: "USD",
  //     intent: "CHARGE",
  //     billingContact: {
  //       familyName: this.state.donor_last_name,
  //       givenName: this.state.donor_first_name,
  //       email: this.state.email,
  //       country: "US",
  //       postalCode: this.state.donor_zip,
  //     }
  //   };
  // }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6" style={{ marginBottom: '200px' }}>

                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>

          </div>
          <section className="section section-lg pt-lg-0 mt--300">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="12">
                      <Card className="shadow border-0" id="don-card">
                        <CardBody className="py-5 card text-center">
                          <h4 className="text-default">
                            Enter your donation
                          </h4>
                          <Col lg="8" style={{ alignSelf: 'center' }}>
                            <FormGroup className={"focused"}>
                              <InputGroup size="sm">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText className="donation-text" style={{ fontSize: '50px', color: 'black' }}>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  className="donation-input"
                                  type='number'
                                  style={{ fontSize: '51px', direction: 'rtl', color: 'black' }}
                                  aria-label="Amount (to the nearest dollar)"
                                  value={this.state.donationAmount}
                                  onChange={this.handleChange.bind(this)}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText className="donation-text" style={{ fontSize: '50px', color: 'black' }}>.00</InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col >

                          <Col lg='8' style={{ marginTop: '50px' }}>
                            <p className="small">
                              *Indicates a required field
                            </p>
                          </Col>

                          <Col lg='8' style={{ alignSelf: 'center' }}>
                            <div className="border-top">

                              <Input
                                type='text'
                                placeholder="First Name*"
                                style={{ marginBottom: "10px", color: 'black' }}
                                value={this.state.donor_first_name}
                                onChange={this.handleChangeFirstName.bind(this)}
                                required
                              />

                              <Input
                                type='text'
                                placeholder="Last Name*"
                                style={{ marginBottom: "10px", color: 'black' }}
                                value={this.state.donor_last_name}
                                onChange={this.handleChangeLastName.bind(this)}
                              />

                              <Input
                                type='text'
                                placeholder="Email Address*"
                                style={{ marginBottom: "10px", color: 'black' }}
                                value={this.state.email}
                                onChange={this.handleChangeEmail.bind(this)}
                              />

                              <Input
                                type='text'
                                placeholder="Zip or Postal Code*"
                                style={{ marginBottom: "10px", color: 'black' }}
                                value={this.state.donor_zip}
                                onChange={this.handleChangeZip.bind(this)}
                              />

                              <Input
                                type='text'
                                placeholder="Comment"
                                style={{ marginBottom: "10px", height: "80px" }}
                                value={this.state.donor_comment}
                                onChange={this.handleChangeComment.bind(this)}
                              />

                            </div>

                          </Col>

                          <Col lg='8'>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="customCheck2"
                                type="checkbox"
                                onChange={() => this.setState({ alertChecked: true, is_anonymous: 1 })}
                              />
                              <label className="custom-control-label" htmlFor="customCheck2">
                                <span style={{ fontSize: "13px" }}>Send anonymously</span>
                              </label>
                            </div>
                          </Col>


                          <Col lg='8' style={{ alignSelf: 'center' }}>
                            {this.displayAlert()}
                          </Col>


                          <Col lg='10' style={{ alignSelf: 'center' }}>
                            <div className="border-top" style={{ marginTop: '50px' }}>
                              <h5 style={{ marginTop: '50px' }}>
                                Processing fee
                            </h5>
                              <p>
                                There is a total fee of 7.9% plus $0.30 per donation that will go to our payment processor and to help keep Debt Karma a safe place to donate.
                            </p>
                            </div>
                          </Col>


                          <Col lg='8' style={{ alignSelf: 'center' }}>
                            <div style={{ marginTop: '50px' }}>
                            </div>
                            {this.renderPaymentPage()}

                          </Col>



                        </CardBody>

                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Donate;
